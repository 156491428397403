
  .creditpreloader {
    position: fixed;
    top: 80px;
    width: 100%;
    text-align: center;
    padding: 50px;
    top: 0; right: 0;
    bottom: 0; left: 0; 
    margin: auto;
  }
  
  .creditpreloader a {
    color: #000000;
    font: 800 75% "Open Sans", sans-serif;
    text-transform: uppercase;
    text-decoration: none;
  }